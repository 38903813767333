.cbo-categories{
	padding:0 65% 0 30px;

	.categories-slider{
		overflow:visible;

		.slider-slide{
			padding:0 0;

			.slide-inner{
				position:relative;
				width:100%;
				padding:30px 0;
			}

			.slide-background{
				display:block;
				position:absolute;
				width:100px;
				padding-bottom:100px;
				border-radius:45px;
				background:var(--ion-color-primary);
				top:50%; left:50%;
				transform:translateX(-50%) translateY(-50%) rotate(-45deg) scale(.8);
				opacity:0;
				transition:all .3s var(--easeinout2);
			}

			.slide-picture{
				position:relative;
				display:flex;
				align-items:center;
				justify-content:center;
				height:50px;
				margin:0 auto;

				ion-img,
				img{
					width:auto;
					height:45px;
					transition:filter .4s;
				}
			}

			.slide-title{
				position:relative;
				color:white;
				font-size:11px;
				font-weight:600;
			}
		}
	}

	&:not(.categories--currentMode){

		.categories-slider{
		
			.slider-slide.swiper-slide-active{

				.slide-background{
					transform:translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
					opacity:1;
				}

				.slide-picture{

					ion-img{
						filter: brightness(0) invert(1);
					}
				}
			}
		}
	}

	&.categories--currentMode{

		.categories-slider{
		
			.slider-slide.slide--current{

				.slide-background{
					transform:translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
					opacity:1;
				}

				.slide-picture{

					ion-img{
						filter: brightness(0) invert(1);
					}
				}
			}
		}
	}
}