.cbo-article-category{
	display:inline-block;
	line-height:1em;
	border-radius: 15px;
	background-color:var(--ion-color-primary);
	padding:5px 15px;
	color:white;
	font-size: 13px;
	font-weight: 700;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipis;
	text-transform:uppercase;
	max-width:100%;
}