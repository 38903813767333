/* Article cards */

.cbo-article{
	position:relative;
	overflow:hidden;
	border-radius:23px;
	box-shadow: 0 2px 30px rgba(0, 0, 0, 0.23);
	opacity:1;
	max-height:600px;
	transition:max-height .6s ease-out, opacity .6s ease-out;

	.article-picture{
		position:relative;
		overflow:hidden;
		border-radius:0 0 90px 0;
		width:100%;
		padding-bottom:50%;
		background:#f1f1f1;

		ion-img,
		img{
			display:block;
			width:100%;
			height:100%;
			position:absolute;
			top:0; left:0;
			object-fit:cover;
		}
	}

	.article-content{
		text-align:left;
		padding:20px;
		position:relative;

		.content-title{
			font-size:26px;
			color:var(--ion-text-color);
			font-weight:bold;
		}

		.content-actions{
			
		}
	}

	.article-status{
		position:relative;
		padding:10px 0 9px 10px;
	}

	.article-actions{
		position:absolute;
		right:0; bottom:0;
	}

	&.article--hidden{
		margin-top:0;
		margin-bottom:0;
		opacity:0;
		max-height:0;
		transition-timing-function:ease-out;
	}

	&.article--small{
		box-shadow: 0 1px 20px rgba(0, 0, 0, 0.14);

		.article-picture{
			border-radius:0 0 60px 0;
		}

		.article-content{
			padding-top:10px;
			padding-bottom:16px;

			.content-title{
				font-size:18px;
			}
		}
	}

	&.article--done{

		.article-content{
			text-align:center;
			padding:30px 30px 40px 30px;

			.content-logo{
				width:160px;
				height:auto;
				margin:20px auto;
			}

			.content-title{
				color:var(--ion-color-primary);
				font-size: 30px;
				font-weight: 100;
				margin:0 0 20px 0;
			}

			.content-text{
				color: #9e9e9e;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.38em;
			}
		}
	}
}