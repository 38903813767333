.cbo-mumlevel{
	padding:10px 0 30px 0;

	.mumlevel-stars{
		display:flex;
		align-items:center;
		width:80%;
		margin:0 auto 10px auto;
	
		.stars-el{
			width:14.67%;
			margin:0 1%;
		}
	}

	.mumlevel-label{
		text-align:center;
	}

	&.mumlevel--reverseColors{

		.mumlevel-stars{
			background:white;
			width:100%;
			padding:8% 10%;
			border-radius:50px;
		}

		.mumlevel-label{
			color:white;
		}
	}
}