@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Medium.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Colors */

.cbo-bg--sante{ background-color:var(--color-sante) !important;  }
.cbo-bg--eveil{ background-color:var(--color-eveil) !important;  }
.cbo-bg--cuisine{ background-color:var(--color-cuisine) !important;  }
.cbo-bg--entretien{ background-color:var(--color-entretien) !important;  }
.cbo-bg--cosmetique{ background-color:var(--color-cosmetique) !important;  }
.cbo-bg--decoration{ background-color:var(--color-decoration) !important;  }

/* Commons & Typography */

ion-app {
	max-width: 650px;
	margin:0 auto;
}

.open_picker {
	//touch-action: none;
}

.cbo-button{
	--box-shadow:none;
	text-transform:none;
	font-weight:700;
	letter-spacing:0;
}

.cbo-secondtitle{
	color: #373737;
	font-size: 26px;
	font-weight: 700;

	strong{
		font-size:1.5em;
		font-weight:100;
	}
}

.cbo-thirdtitle{
	font-size: 18px;
	font-weight: 700;
	margin:30px 0 0 0;

	strong{
		font-size:1.7em;
		font-weight:100;
	}
}

.cbo-hint{
	font-size:12px;
	display:block;
	margin:10px 0;
	color:#aaa;

	&.hint--invalid{
		color:var(--ion-color-danger);
	}
}

.cbo-cms{

	h2{
		color: #1e4f90;
		font-size: 18px;
		font-weight: 700;
		line-height:1.2em;
	}

	p, ul, ol{
		color: #9e9e9e;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.38em;
	}

	img{
		display:block;
		width:100% !important;
		height:auto !important;
		margin:15px auto;
	}

	.chapo{
		background:var(--ion-color-primary);
		margin:16px -16px;
		padding:var(--ion-padding, 16px);
		text-align:center;

		p, ul, ol{
			color:white;
			font-size:18px;
			font-weight:700;
		}
	}

	.content{
		margin:16px 0;
	}

	.push{
		background:var(--ion-color-primary);
		color:white;
		margin:16px -16px;
		padding:30px 30px 60px 30px;
		text-align:center;

		h2{
			color:white;
			font-size:30px;
			font-weight:100;
		}	

		p, ul, ol{
			color:white;
			font-size: 16px;
			font-weight: 400;
			line-height: 1.38em;
		}

		img{
			display:block;
			width:280px;
			height:auto;
			margin:30px auto;
		}

		a{
			display:inline-block;
			height: 64px;
			line-height:64px;
			border-radius: 32px;
			background-color: #ffffff;
			text-decoration:none;
			padding:0 30px;
			min-width:250px;
			text-align:center;
			color:var(--ion-color-primary);
			font-size: 18px;
			font-weight: 700;
		}
	}
}

.cbo-tabsbar--hidden{
	display:none !important;
}

/* Side menu */

.cbo-menu{

	.menu-content{
		--background:var(--ion-color-primary);
		--padding-start:35px;
		--padding-end:35px;
		--padding-top:75px;

		.content-header{
			width:195px;
			height:auto;
			margin-bottom:30px;
			padding-left:18px;
		}

		.content-list.list-ios{
			background:transparent;

			.list-el{
				--background:transparent;

				.el-inner{
					font-size:16px;
					font-weight:300;
				}
			}
		}
	}

	.menu-footer{
		padding:0 35px 35px 35px;
		position:relative;

		&:before{
			content:'';
			display:block;
			position:absolute;
			width:100%;
			height:180px;
			background:url(../assets/img/waves-white.svg) no-repeat top;
			background-size:100% auto;
			position:absolute;
			top:-80px; left:0;
		}

		ion-img{
			position:relative;
			width:160px;
			height:auto;
			margin:0 auto;
		}
	}	
}

/* Tab bar */

.cbo-tabbar{
	box-shadow:none;
	overflow:visible;
	z-index:10;
	position:relative;
	contain:none;
	height:50px;
	--background:none;
	--border:none;

	&:before{
		content:'';
		display:block;
		width:200%;
		height:120px;
		background:url(../assets/img/tabbar-background.png) 50% 0 repeat-x;
		background-size:100% 100%;
		position:absolute;
		bottom:-20px;
		left:50%;
		transform:translateX(-50%);
		transition:transform .5s var(--easeinout2);
	}

	&[selected-tab="tab-search"]{
		&:before{ transform:translateX(-66.6666%); }
	}

	&[selected-tab="tab-settings"]{
		&:before{ transform:translateX(-33.3333%); }
	}

	.tabbar-button{
		height:50px;
		transition:transform .5s var(--easeinout2);

		.button-icon{
			font-size:20px;
			stroke:#979797;
			transition:stroke .5s;
		}

		.button-label{
			display:block;
			position:relative;
			overflow:hidden;
			opacity: 0.4;
			color: #000000;
			font-size: 12px;
			font-weight: 500;
			max-height:0;
			transition:max-height .5s var(--easeinout2), margin-top .5s var(--easeinout2);
		}

		&.tab-selected{
			transform:translateY(-15px);

			.button-icon{
				stroke:var(--ion-color-primary);
			}

			.button-label{
				max-height:16px;
				margin-top:4px;
			}
		}
	}
}

/* Refresher */

.cbo-refresher{

	.refresher-button{
		margin-top:15px;
		--color:var(--ion-color-primary);
	}
}

/* Modal */

.cbo-modal{
	--height:auto;
	--min-width:50%;
	--min-height:270px;
	--max-width:80%;
	--max-height:80%;
	--border-radius:9px;

	.modal-wrapper{
		overflow:visible;

		.ion-page{
			overflow:visible;
		}
	}

	.modal-inner{
		padding:30px 30px;
		text-align:center;
		min-height:100%;
		display:flex;
		flex-direction:column;
		justify-content:center;
		position:relative;

		&:before{
			content:'';
			display:inline-block;
			width:107px;
			height:104px;
			background:url(../assets/img/icon-check.svg) no-repeat center;
			background-size:100% auto;
			position:absolute;
			top:-52px;
			left:50%;
			margin-left:-53.5px;
		}
	}

	.modal-title{
		margin:35px 0 15px 0;
	}

	.modal-content{
		color: #9e9e9e;
		font-size:16px;
		font-weight:400;
		line-height:1.38em;

		ion-img{
			width:155px;
			height:auto;
			margin:15px auto 0 auto;
		}
	}

	.modal-close{
		display:block;
		width:57px;
		height:55px;
		background:url(../assets/img/icon-close.svg) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-17px;
		right:-19px;
	}
}

/* Newlevel modal */

.cbo-levelmodal{
	--min-width:100%;
	--min-height:100%;

	.levelmodal-top{
		position:relative;
		background:var(--ion-color-primary);
		padding:60px 30px 150px 30px;
		text-align:center;

		&:after{
			content:'';
			display:block;
			position:absolute;
			width:100%;
			height:180px;
			background:url(../assets/img/waves-white.svg) no-repeat top;
			background-size:100% auto;
			position:absolute;
			bottom:-80px; left:0;
		}
	}

	.levelmodal-offer{
		padding:30px;
		text-align:center;
		position:relative;

		.offer-title{
			display:block;
			padding:0 10%;
			margin:0 0 10px 0;
		}

		.offer-text{
			display:block;
			font-size:16px;
			color:#9e9e9e;
		}

		.offer-picture{
			max-width:75%;
			margin:15px auto;
		}
	}

	.levelmodal-branding{
		padding:30px;
		background:var(--ion-color-primary);
		text-align:center;
		position:relative;

		&:before{
			content:'';
			display:block;
			position:absolute;
			width:100%;
			height:180px;
			background:url(../assets/img/waves-white.svg) no-repeat top;
			background-size:100% auto;
			position:absolute;
			top:0; left:0;
			transform:rotate(180deg);
		}

		.branding-picture{
			position:relative;
			width:270px;
			height:auto;
			margin:0 auto 30px auto;

		}

		.branding-title{
			text-align:center;

			&:before{
				content:'';
				display:block;
				width:200px;
				height:60px;
				background:url(../assets/img/logo-symbol.svg) no-repeat;
				background-size:100% auto;
				margin:0 auto 15px auto;
			}
		}
	}

	&.levelmodal--branding{

		.levelmodal-top{
			padding-bottom:30px;

			&:after{
				display:none;
			}
		}

		.levelmodal-branding{

			&:before{
				display:none;
			}
		}

		.levelmodal-offer{
			display:none;
		}


	}

	.levelmodal-close{
		display:block;
		width:57px;
		height:55px;
		background:url(../assets/img/icon-close-light.svg) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:25px;
		right:20px;
	}
}

/* Home */

.cbo-home{

	.home-content{
		--padding-top:80px;
		--padding-start:50px;
		--padding-end:50px;

		img{
			width:100%;
			height:auto;
		}
	}

	.home-footer{
		padding-top:140px;
		background:url(../assets/img/waves-white.svg) no-repeat top var(--ion-color-primary);
		background-size:cover;
	
		.cbo-button{
			margin-bottom:15px;
		}

		.footer-connection{
			display:block;
			text-align:center;
			margin-bottom:25px;

			h1{
				font-weight:bold;
				font-size:20px;
			}
		}
	}
}

/* Login & Subscribe */

.cbo-page{

	.page-header{
		background:var(--ion-color-primary);
	}

	.page-content{

		.content-title{
			padding-top:10px;
			padding-bottom:60px;
			margin:0;
			text-align:center;
			background:var(--ion-color-primary);
			color:white;
			font-size:25px;
			font-weight:700;
		}

		.content-main{
			padding-top:30px;
			padding-bottom:30px;
			border-radius:30px 30px 0 0;
			background:white;
			margin-top:-30px;
		}
	}
}

/* Singles & Pages */

.cbo-single{

	.single-header{
		position:fixed;

		.header-toolbar{
			--background: transparent;
			--ion-color-base: transparent !important;
		}
	}

	.single-hero{
		position:relative;
		overflow:hidden;
		border-radius:0 0 0 110px;
		width:100%;
		padding-bottom:60%;
		background:#f1f1f1;

		ion-img,
		img{
			display:block;
			width:100%;
			height:100%;
			position:absolute;
			top:0; left:0;
			object-fit:cover;
		}
	}

	.single-category{
		text-align:center;
		margin:20px 0 -30px 0;
	}

	.single-title{
		color: #1e4f90;
		font-size: 32px;
		font-weight: 700;
		line-height: 1.16em;
		text-align:center;
	}

	.single-status{
		text-align:center;
		margin:-10px 0 -20px 0;
	}

	.single-footer{
		padding-bottom:60px;

		ion-img{
			width:170px;
			height:auto;
			margin:0 auto;
		}
	}
}

/* Dashboard */

.cbo-dashboard{

	.dashboard-header{

		.header-user{
			color:var(--ion-text-color);
			font-weight:normal;
			font-size:18px;
		}
	}

	.dashboard-main{
		position:relative;
		background:var(--ion-color-primary);
		padding-bottom:50px;

		&:before,
		&:after{
			content:'';
			display:block;
			width:100%;
			padding-bottom:22%;
			background:url(../assets/img/waves-white.svg) no-repeat top;
			background-size:100% auto;
			position:absolute;
		}

		&:before{
			transform:rotate(180deg);
			top:0; left:0;
			padding-bottom:50%;
		}

		&:after{
			transform:scaleX(-1);
			bottom:-1px; left:0;
		}

		.main-inner{
			position:relative;
			z-index:1;
		}
	}

	.dashboard-userarticles{

		.cbo-thirdtitle{
			color:white;
		}

		.cbo-article{
			width:100%;
			margin:0;
		}
	}

	.dashboard-categories{
		position:relative;
	}

	.dashboard-footer{
		padding:30px 0 60px 0;
		position:relative;

		ion-img{
			width:170px;
			height:auto;
			margin:0 auto;
		}
	}
}

/* Search */

.cbo-search{

	.search-title{
		padding-top:80px;

		.cbo-secondtitle{
			margin-top:0;
		}
	}

	.search-header{
		position:fixed;
		background: rgba(255,255,255,0.35);

		.header-toolbar{
			--background: transparent;
		}
	}

	.search-main{
		padding-top:25px;
		padding-bottom:50px;

		.cbo-article{
			margin-top:0;
		}

		.main-content{

			.content-result{
				display:block;
				padding:30px;
				color:var(--ion-color-primary);
				text-align:center;
				font-weight:bold;
			}

			.cbo-loading{

				.loading-text{
					padding-top:0;
				}
			}
		}
	}

	.search-filters{
		margin-top:-10px;
		margin-bottom:-10px;

		.filters-keywords{
			height: 53px;
			border-radius: 30px;
			margin-bottom:15px;
			background-color: #f5f5f5;
			--background:none;
			--placeholder-color:var(--ion-text-color);
			--placeholder-opacity:1;
			--icon-color:var(--ion-color-primary);


			.searchbar-input{
				font-size:14px;
			}

			ion-icon{
				width:30px;
				left:0;
			}
		}

		.filters-hasbaby{
			margin-bottom:15px;

			ion-segment{
				--background: #f5f5f5;
				border-radius:16px;

				ion-segment-button{
					--border-radius:16px;
					--color-checked:white;
					--indicator-box-shadow:none;
					--indicator-color:var(--ion-color-primary);
				}
			}

			.button-label{
				font-size:10px;
			}
		}

		.filters-categories{
			margin: 0 -16px;
		}

		.filters-count{
			margin-top:15px;
			color: #30333c;
			font-size:12px;
			font-weight:400;
			line-height:1em;
			height:12px;
			text-align:center;
		}
	}
}