.cbo-loading{
	display:block;
	text-align:center;
	
	.loading-text{
		display:block;
		padding:100px 30px 30px 30px;
		text-align:center;
		font-size:16px;
		font-weight:100;
		color:var(--ion-text-color);
	}

	.loading-spinner{
		display:block;
		width:30px;
		margin:0 auto;
	}
}