/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */


/** Fonts **/
:root,
:root[mode="md"],
:root[mode="ios"] {
  --ion-font-family: 'Poppins', sans-serif;
  --ion-text-color: #1e4f90;
  font-family: var(--ion-font-family) !important;
}


/** Ionic CSS Variables **/
:root {

  /** transitions **/
  --easeinout:  cubic-bezier(0.215, 0.600, 0.270, 0.995);
  --easeinout2: cubic-bezier(0.85, 0, 0.15, 1);
  --easeinout3: cubic-bezier(.43,.38,.47,.86);

  /** categories **/
  --color-sante: #9fceec;
  --color-entretien: #ff9281;
  --color-beaute: #b9b7d8;
  --color-eveil: #72d3c2;
  --color-cosmetique: #a5afea;
  --color-cuisine: #feb281;
  --color-decoration: #f4e178;

  /** primary **/
  --ion-color-primary: #b2d861;
  --ion-color-primary-rgb: 178, 216, 97;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b2d861;
  --ion-color-primary-tint: #b2d861;

  /** secondary **/
  --ion-color-secondary: #f3f4fa;
  --ion-color-secondary-rgb: 243, 244, 250;
  --ion-color-secondary-contrast: #9e9e9e;
  --ion-color-secondary-contrast-rgb: 233, 233, 233;
  --ion-color-secondary-shade: #f3f4fa;
  --ion-color-secondary-tint: #f3f4fa;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {

  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {

  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {

  }
}
