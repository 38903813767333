.cbo-article-status{
	display:inline-block;

	.status-inner{
		display:flex;
		align-items:center;
		font-size:13px;
		font-weight:700;
		text-transform:uppercase;

		ion-img{
			width:auto;
			height:10px;
			margin:0 5px 0 0;
		}

		&.status--pending{ color:#9e9e9e; }
		&.status--validated{ 
			color:var(--ion-color-primary); 

			ion-img{
				height:13px;
			}
		}
	}
}